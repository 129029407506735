// scafholding
* {
  outline: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  color: $body-color;
  background: $main-body-bg;
}

html {
  position: relative;
  min-height: 100%;
}

a {
  color: $themecolor;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: $body-color;

  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.box {
  border-radius: $border-radius;
  padding: 10px;
}

.no-wrap {
  td,
  th {
    white-space: nowrap;
  }
}

/*******************
Blockquote
*******************/

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Thumb size
*******************/

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.radius {
  border-radius: $border-radius;
}

/*******************
Opacity
*******************/

.op-5 {
  opacity: 0.5;
}

.op-3 {
  opacity: 0.3;
}

/*******************
font weight
*******************/

html body {
  .font-bold {
    font-weight: $font-weight-bold;
  }

  .font-normal {
    font-weight: $font-weight-normal;
  }

  .font-light {
    font-weight: $font-weight-light;
  }

  .font-medium {
    font-weight: $font-weight-medium;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-10 {
    font-size: 10px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-20 {
    font-size: 20px;
  }

  .font-22 {
    font-size: 22px;
  }

  .font-24 {
    font-size: 24px;
  }

  // Type display classes
  .display-5 {
    font-size: $display5-size;
    font-weight: $display5-weight;
    line-height: $display-line-height;
  }

  .display-6 {
    font-size: $display6-size;
    font-weight: $display6-weight;
    line-height: $display-line-height;
  }

  .display-7 {
    font-size: $display7-size;
    font-weight: $display7-weight;
    line-height: $display-line-height;
  }

  .blockquote {
    border-left: 5px solid $primary;
    border: 1px solid $border-color;
    padding: 15px;
  }
}

/*******************
Background colors
*******************/

html body {
  .bg-megna {
    background-color: $cyan;
  }

  .bg-theme {
    background-color: $themecolor;
  }

  .bg-inverse {
    background-color: $gray-900;
  }

  .bg-purple {
    background-color: $purple;
  }

  .bg-light {
    background-color: $gray-200;
  }

  .bg-white {
    background-color: $white;
  }
}

/*******************
Labels
*******************/

.label-rounded {
  border-radius: 60px;
}

/*******************
 Badge
******************/

.badge {
  font-weight: $font-weight-light;
  line-height: normal;

  &.badge-pill {
    padding: 0.2em 0.6em;
  }
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

/*******************
List-style-none
******************/

ul.list-style-none {
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;

    a {
      color: $body-color;
      padding: 8px 0px;
      display: block;
      text-decoration: none;

      &:hover {
        color: $themecolor;
      }
    }
  }
}

/*******************
Card
******************/

.card {
  margin-bottom: 30px;
  overflow: visible !important;

  .card-subtitle {
    font-weight: $font-weight-light;
    margin-bottom: 10px;
    color: $text-muted;
  }

  .card-title {
    position: relative;
    font-weight: $font-weight-medium;
    margin-bottom: 10px;
  }

  .card-actions {
    float: $rgt;

    a {
      padding: 0 5px;
      cursor: pointer;
    }
  }

  .card-header .card-title {
    margin-bottom: 0px;
  }
}

.card-alt {
  margin: 0 -20px;
  background: $card-alt;
}

.card-group {
  margin-bottom: 30px;

  .card {
    border-right: 1px solid $border-color;
  }
}
.oh {
  overflow: hidden;
}

.accordion {
  .card {
    margin-bottom: 2px;
  }
}
